import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Input from "../../UI/input/Input";
import Button from "../../UI/button/Button";
import FormButton from "../../UI/button/FormButton";
import { getGrantCampaign } from "../../../store/slices/grantCampaign";
import { clearResponse, updateGrantCampaign } from "../../../store/actions";
import { connect } from "react-redux";
import DateInput from "../DateInput"
import StatusSelect from "../StatusSelect"
import ToggleSwitch from "../../common/toggleSwitch";

class Update extends Component {
  state = {
    updateCampaignName: {
      elementType: "input",
      elementConfig: {
        name: "updateCampaignName",
        required: true,
      }
    },
    updateCampaignDescription: {
      elementType: "textarea",
      elementConfig: {
        name: "updateCampaignDescription",
        required: true,
        rows: 4
      }
    },
    updateCampaignDeadline: {
      name: "updateCampaignDeadline",
      label: "Application Deadline",
    },
    status: this.props.status,
    name: this.props.name,
    description: this.props.description,
    deadline: this.props.deadline,
    allow_multiple_applications: this.props.allow_multiple_applications,
    disabled: false,
  };

  inputChangedHandler = (event) => {
    this.setState({ [event.target.name.slice(14).toLowerCase()]: event.target.value }); //Needs a refactor
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    let response = this.props.response
    if (response && !response.loading && response.hasOwnProperty("success") && response.success !== null) {
      const { message, success } = response;
      this.props.clearResponse();
      if (success) {
        toast.success(message);
        this.props.getGrantCampaign(this.props.id);
        this.props.closeModal(true);
      } else {
        toast.error(message);
      }
    }
  }

  setStatus = (status) => {
    this.setState({...this.state, status: status})
  }

  submitHandler = (event) => {
    event.preventDefault();
    this.setState({ disabled: true });
    const formData = {
      id: this.props.id,
      name: this.state.name,
      description: this.state.description,
      deadline: this.state.deadline,
      status: this.state.status,
      allow_multiple_applications: this.state.allow_multiple_applications
    };
    this.props.updateGrantCampaign(formData, this.props.history);
  };

  render() {
    return (
      <form onSubmit={this.submitHandler} className="mt-4">
        <p>Edit Campaign</p>
        <Input
          {...this.state.updateCampaignName}
          changed={(event) => this.inputChangedHandler(event)}
          value={this.state.name}
        />
        <Input
          {...this.state.updateCampaignDescription}
          changed={(event) => this.inputChangedHandler(event)}
          value={this.state.description}
        />
        <StatusSelect status={this.state.status} setStatus={this.setStatus} />
        <DateInput 
          stateObject={{...this.state.updateCampaignDeadline, value: this.state.deadline}}
          onChangeFunction={this.inputChangedHandler}
          invalidMessage="Please set a deadline"
        />
        <div className="my-3 d-flex align-items-center">
          <ToggleSwitch
            checked={this.state.allow_multiple_applications}
            onChange={() => this.setState({ allow_multiple_applications: !this.state.allow_multiple_applications})}
            disabled={this.state.disabled}
          />
          <label className="ml-2 my-0">
            Allow users to submit multiple applications?
          </label>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <Button
            type="secondary"
            className="mr-2"
            clicked={this.props.closeModal}
          >
            Cancel
          </Button>
          <FormButton disabled={this.state.disabled}>Update</FormButton>
        </div>
      </form>
    );
  }
}

Update.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  getGrantCampaign: PropTypes.func.isRequired,
  updateGrantCampaign: PropTypes.func.isRequired,
  clearResponse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    response: state.response,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGrantCampaign: (id) => dispatch(getGrantCampaign(id)),
    updateGrantCampaign: (campaignData, history) =>
      dispatch(updateGrantCampaign(campaignData, history)),
    clearResponse: () => dispatch(clearResponse()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Update);
