import React, { useEffect, useReducer } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";
import { clearResponse, duplicateGrantCampaign } from "../../store/actions";
import DateInput from "./DateInput"
import StatusSelect from "./StatusSelect"
import ToggleSwitch from "../common/toggleSwitch";

const DISABLE_FORM = 'DISABLE_FORM'
const INIT_FORM_DATA = 'INIT_FORM_DATA'
const ON_INPUT_CHANGE = 'ON_INPUT_CHANGE'
const ON_STATUS_CHANGE = 'ON_STATUS_CHANGE'
const TOGGLE_MULI_APPLICATION = 'TOGGLE_MULI_APPLICATION'

const initialState = {
  sourceCampaign: {},
  campaignName: {
    name: "campaignName",
    elementType: "input",
    elementConfig: {
      name: "campaign_name",
      type: "text",
      required: true
    },
    value: "",
    label: "Campaign Name"
  },
  campaignDescription: {
    name: "campaignDescription",
    elementType: "textarea",
    elementConfig: {
      name: "campaign_description",
      type: "text",
      rows: 4,
      required: true
    },
    value: "",
    label: "Campaign Description"
  },
  campaignStatus: "Draft",
  campaignDeadline: {
    name: "campaignDeadline",
    value: "",
    label: "Application Deadline"
  },
  campaignMultipleApplications: false,
  disabled: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case DISABLE_FORM:
      return { ...state, disabled: false }
    case INIT_FORM_DATA:
      return {
        ...state,
        campaignName: { ...state.campaignName, value: action.value.name },
        campaignDescription: { ...state.campaignDescription, value: action.value.description },
        campaignMultipleApplications: action.value.allow_multiple_applications,
        sourceCampaign: { ...state.sourceCampaign, ...action.value },
      }
    case ON_INPUT_CHANGE:
      return { ...state, [action.field]: { ...state[action.field], value: action.value } }
    case ON_STATUS_CHANGE:
      return { ...state, campaignStatus: action.value }
    case TOGGLE_MULI_APPLICATION:
      return { ...state, campaignMultipleApplications: action.value }
    default:
      return state
  }
}

const DuplicateCampaign = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: INIT_FORM_DATA, value: props.campaign })
  }, []);


  const onStatusChange = (status) => {
    dispatch({ type: ON_STATUS_CHANGE, value: status });
  }

  const onFormSubmit = async e => {
    e.preventDefault();

    const { duplicateGrantCampaign, getGrantCampaigns } = props

    dispatch({ type: DISABLE_FORM })

    const campaignData = {
      id: state.sourceCampaign.id,
      name: state.campaignName.value,
      description: state.campaignDescription.value,
      deadline: state.campaignDeadline.value,
      status: state.campaignStatus,
      allow_multiple_applications: state.campaignMultipleApplications
    }

    try {
      await duplicateGrantCampaign(campaignData, getGrantCampaigns)
      toast.success('Campaign Duplicated Successfully!');
    }
    catch (e) {
      toast.error('Something Went Wrong');
    }
  };


  const DuplicateCampaignForm = (
    <>
      <Input
        key={state.campaignName.name}
        {...state.campaignName}
        changed={e =>
          dispatch({ type: ON_INPUT_CHANGE, field: state.campaignName.name, value: e.target.value })
        }
      />
      <Input
        key={state.campaignDescription.name}
        {...state.campaignDescription}
        changed={e =>
          dispatch({ type: ON_INPUT_CHANGE, field: state.campaignDescription.name, value: e.target.value })
        }
      />
      <StatusSelect status={state.campaignStatus} setStatus={onStatusChange} />
      <DateInput
        stateObject={state.campaignDeadline}
        onChangeFunction={e => dispatch({ type: ON_INPUT_CHANGE, field: state.campaignDeadline.name, value: e.target.value })}
        invalidMessage="Please set a deadline"
      />
      <div className="my-3 d-flex align-items-center">
        <ToggleSwitch
          checked={state.campaignMultipleApplications}
          onChange={e => dispatch({ type: TOGGLE_MULI_APPLICATION, value: e })}
          disabled={state.disabled}
        />
        <label className="ml-2 my-0">
          Allow users to submit multiple applications?
        </label>
      </div>

    </>
  );

  return (
    <form onSubmit={onFormSubmit} className="mt-4">
      {DuplicateCampaignForm}
      <div className="text-center mt-3">
        <Button type="secondary" clicked={() => props.closeModal()}>
          Cancel
        </Button>
        <FormButton className="ml-2" disabled={state.disabled}>
          Duplicate Campaign
        </FormButton>
      </div>
    </form>
  );
}

const mapStateToProps = state => {
  return {
    response: state.response,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    duplicateGrantCampaign: (campaignData, callback) =>
      dispatch(duplicateGrantCampaign(campaignData, callback)),
    clearResponse: () => dispatch(clearResponse())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DuplicateCampaign));
